.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.detailedForecast, .period {
  border-style:solid;
  border-width: 1px;
  border-color: white;
  border-spacing: 15px;
}
.zone {
  border-style:solid;
  border-width: 1px 0 1px 0;
  border-color: white;
}


.forecastTable {
  border-collapse: separate;
  border-spacing: 5px 15px;
  width: 75%;
}

.zonesTable {
  border-collapse:collapse;
}

caption {
  font-size: xx-large;
  font-weight: bold;
  border-style:solid;
  border-width: 1px;
  border-color: white;
}

button, select {
  vertical-align: middle;
}

